export const USER_SETTINGS_ENDPOINTS = {
  serviceName: 'apiMicroserviceUserSettings',
  paths: {},
};

export const USER_SECURITY_ENDPOINTS = {
  serviceName: 'apiMicroserviceUserSecurity',
  paths: {},
  getAllPeople: '/Profile/SearchUser',
};

export const ADMIN_ENDPOINTS = {
  serviceName: 'apiMicroserviceAdmin',
  paths: {},
};

export const ONBOARDING_ENDPOINTS = {
  serviceName: 'apiMicroserviceOnboarding',
  paths: {},
};

export const EVENT_ENDPOINTS = {
  getAll: 'Event/GetAll',
  getRevamp: '/Event/GetAllRevamp',
};

export const SHOP_ENDPOINTS = {
  getAll: 'Event/GetAll',
  getExplore: '/Event/GetAllRevamp',
  getGrouped: '/Event/GetGroupedEvents',
  getBySlug: '/Event/GetAllRevamp',
  getProductCategories: '/Shop/shop-categories',
  createEvent: '/Event/CreateEventStore',
  updateEvent: '/Event/Update',
  getEventById: '/Event/GetById',
  getEventBySlug: '/Event/GetBySlug',
  registerForFreeEvent: '/Event/RegisterFreeEvent',
  upcomingEvents: '/Event/GetUpcoming',
  cancelEvent: '/Event/XXXXX',
  openCloseTicketSales: '/Event/yyyyyyy',
  sendEventRemainder: '/Event/yyyyyyy',
  downloadAttendeeList: '/Event/yyyyyyy',
  getAttendeeList: '/Event/GetAttendees',
};

export const COMMUNITIES_ENDPOINTS = {
  getUserJoined: '/Community/GetUserJoinedCommunities',
  getAll: '/Community/GetAll',
  getSidePanelData: '/Community/CommunitySidePanelDetails',
  getCommunityById: '/Community/GetById',
  getCommunityBySlug: '/Community/GetBySlug',
  getCommunityPostsById: '/post/getPosts',
  joinOrLeave: '/Community/JoinOrLeave',
  approveOrReject: '/Community/ApproveOrRejectJoinPrivateCommunityRequest',
  getUserJoinedCommunities: '/Community/GetUserJoinedCommunities',
  members: '/Community/GetMembers',
  categories: '/Community/Categories',
  interests: '/Community/Interests',
  userInterests: '/Community/GetAllByUserInterests',
  create: '/Community/Create',
  update: '/Community/Update',

  getAllPosts: '/Post/GetPosts',
  postsByCommunity: '/Post/GetByCommunity',
  getPopularPosts: '/Post/PopularPosts',
  getUserFeeds: '/Post/GetForUserFeedM',
  getExplore: '/Community/GetAllExplore',

  getAllPeople: '/People/GetAllPeople',

  getFollowing: '/Connections/get-following',
  getCommunityOwners: '/Community/community-owners',
  getPopularCreators: '/Post/post-creators',
  getOthers: '/Community/others',
  connectToUser: '/Connections/connect-to-user',
  unfollowUser: '/Connections/unfollow-user',
  checkIsFollowing: '/Connections/following',
  toggle: '/Community/toggle',
  delete: '/Community/Delete',
  transferOwnership: '/Community/TransferOwnership',
  getSettings: '/Community/GetCommunitySettings',
  updateSettings: '/Community/UpdateCommunitySettings',
  removeMember: '/Community/RemoveMembers',
  removeAllMembers: '/Community/RemoveAllMembers',
  updateMembersRole: '/Community/ChangeMemberRole',
};

export const NOTIFICATIONS_ENDPOINTS = {
  getAll: 'Store/GetUserNotifications',
  toggleReadStatus: 'Store/ToggleReadStatusNotification',
  deleteNotification: 'Store/DeleteUserNotifications',
  markAllAsRead: '/Store/MarkAllRead',
};

export const COMMENTS_ENDPOINTS = {
  commentsBySlug: '/Comment/GetByContentSlug',
  commentsById: '/Comment/GetByContentId',
  commentsCreate: '/Comment/Create',
};

export const WALLET_ENDPOINTS = {
  serviceName: 'apiMicroServiceWallet',
  paths: {},
  createEventPaymentLink: '/SterlingFusion/CreateEventPaymentLink',
  unathenticatedCreateEventPaymentLink: '/SterlingFusion/CreateEventPaymentLinkForUnathorizedUsers',
  verifyEventPaymentWithBankTransfer: '/SterlingFusion/VerifyPayment',
  eventPaymentWithWallet: '/SterlingFusion/EventPayment',
  verifyNIN: '/SterlingFusion/NINVerification',
  verifyBVN: '/SterlingFusion/ValidateBVN',
  validateManualNIN: '/SterlingFusion/NinManualVerification',
  validateNINOTP: '/SterlingFusion/OTPValidation',
  createWalletPin: '/SterlingFusion/WalletPin',
  createTransactionPin: '/SterlingFusion/TransactionPin',
  validateOtp: '/SterlingFusion/OTPValidation',
  validateBVNOTP: '/SterlingFusion/BvnOtpValidation',
  getWalletDetails: '/SterlingFusion/GetWalletDetailsRevamp',
};
